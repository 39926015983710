import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { products } from '../../utils/products';
import ItemList from '../ItemList/ItemList';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import './ItemListContainer.css';


const ItemListContainer = ({ greeting }) => {

    const [availableProducts, setProducts] = useState([])
    const [loading, setLoading] = useState(true)

    const { category } = useParams()

    const getProducts = async () => {
        const productList = products;

        setLoading(false)
        category === undefined ?
            setProducts(productList) :
            setProducts(productList.filter(el => el.category === category));

    };

    useEffect(() => {

        getProducts()

        return (() => {
            setLoading(true);
            setProducts([])
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category])

    return (
        <>  
            <h1>Tienda de Templo</h1>
            <section>
                {greeting && <h2>{greeting}</h2>}
                {category !== undefined && <h2>{`Productos de la categoría ${category}`}</h2>}
                <div className="item-list__container">
                    {loading ? <SpinnerLoader /> : <ItemList items={availableProducts} />}
                </div>
            </section>
        </>
    );
}

export default ItemListContainer