const categories = [
    "Tops",
    "Buzos",
    "Pantalones"
]

const products = [
    {
        'id': 1,
        'title': "Strapless",
        'category': "Tops",
        'description': "Strapless talle unico",
        'pic1': "strap/strap_2.jpg",
        'pic2': "strap/strap_5.jpg",
        'pic3': "strap/strap_3.jpg",
        'pic4': "strap/strap_4.jpg",
        'price': 300,
        'sizes': ["20cm"],
        'stock': 4
    },
    {
        'id': 2,
        'title': "Buzo de lana rojo",
        'category': "Buzos",
        'description': "Buzo de lana",
        'pic1': "buzo_rojo/buzo_rojo_1.jpg",
        'pic2': "buzo_rojo/buzo_rojo_2.jpg",
        'pic3': "buzo_rojo/buzo_rojo_3.jpg",
        'pic4': "buzo_rojo/buzo_rojo_4.jpg",
        'price': 1500,
        'sizes': ["S"],
        'stock': 2
    },
    {
        'id': 3,
        'title': "Buzo de lana mostaza",
        'category': "Buzos",
        'description': "Buzo de lana",
        'pic1': "buzo_mostaza/buzo_mostaza_1.jpg",
        'pic2': "buzo_mostaza/buzo_mostaza_2.jpg",
        'pic3': "buzo_mostaza/buzo_mostaza_3.jpg",
        'pic4': "buzo_mostaza/buzo_mostaza_4.jpg",
        'price': 1500,
        'sizes': ["S"],
        'stock': 2
    },
    {
        'id': 4,
        'title': "Pantalones",
        'category': "Pantalones",
        'description': "Pantalones",
        'pic1': "pantalones/pantalones_1.jpg",
        'pic2': "pantalones/pantalones_2.jpg",
        'pic3': "pantalones/pantalones_3.jpg",
        'pic4': "pantalones/pantalones_4.jpg",
        'price': 1000,
        'sizes': ["S"],
        'stock': 2
    },
    {
        'id': 5,
        'title': "Sosten",
        'category': "Tops",
        'description': "Sosten",
        'pic1': "sosten/sosten_2.jpg",
        'pic2': "sosten/sosten_2.jpg",
        'pic3': "sosten/sosten_3.jpg",
        'pic4': "sosten/sosten_4.jpg",
        'price': 1000,
        'sizes': ["S"],
        'stock': 2
    },
    {
        'id': 6,
        'title': "Top",
        'category': "Tops",
        'description': "Top",
        'pic1': "top1/top_1_1.jpg",
        'pic2': "top1/top_1_2.jpg",
        'pic3': "top1/top_1_3.jpg",
        'pic4': "top1/top_1_4.jpg",
        'price': 1000,
        'sizes': ["S"],
        'stock': 2
    },
    {
        'id': 7,
        'title': "Top",
        'category': "Tops",
        'description': "Top",
        'pic1': "top2/top_2_1.jpg",
        'pic2': "top2/top_2_2.jpg",
        'pic3': "top2/top_2_3.jpg",
        'pic4': "top2/top_2_4.jpg",
        'price': 1000,
        'sizes': ["S"],
        'stock': 2
    },
    {
        'id': 8,
        'title': "Polera",
        'category': "Buzos",
        'description': "Top",
        'pic1': "top3/top_3_1.jpg",
        'pic2': "top3/top_3_2.jpg",
        'pic3': "top3/top_3_3.jpg",
        'pic4': "top3/top_3_4.jpg",
        'price': 1000,
        'sizes': ["S"],
        'stock': 2
    },
]

export { categories, products };
