import EmailIcon from '@mui/icons-material/Email';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__description">
                <img
                    src="../assets/icons/logo-templo.svg"
                    alt="Logo de Templo Design"
                    className="logo-bike-store"
                />
                <p className="footer-text">Este es mi Templo</p>
            </div>
            <div className="footer__contact">
                <h3 className="footer-title">Diseñado por Flor G. Pazos</h3>
                <ul className='contact__icons'>
                    <li><a href='mailto:fedekrenn@gmail.com' target='_blank' rel='noreferrer'><EmailIcon/></a></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer