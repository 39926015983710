import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Swal from 'sweetalert2';
import './ItemDetail.css';

const ItemDetail = ({ prop }) => {

    const { title, price, stock, pic1, pic2, pic3, pic4, sizes, description, id } = prop;

    const [size, setSize] = useState(null)
    const [bike, setBike] = useState(pic1)
    const [quantity, setQuantity] = useState(1)
    const [showButton, setShowButton] = useState(false)

    const onAdd = () => {
        if (size !== null) {
            setShowButton(true)
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Tienes que seleccionar el talle que deseas',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }
    }

    // Función para seleccionar la imagen principal en el grid
    const selectBike = (img) => {
        setBike(img)
    }

    return (
        <>
            <h1>Características de la prenda</h1>
        <div className="container">

            <img className="primary-pic" alt={`${title}`} src={`../assets/productos/${bike}`} />

            <img className="pic1 pic-detail" onClick={() => selectBike(pic1)} alt={`${title}`} src={`../assets/productos/${pic1}`} />
            <img className="pic2 pic-detail" onClick={() => selectBike(pic2)} alt={`${title}`} src={`../assets/productos/${pic2}`} />
            <img className="pic3 pic-detail" onClick={() => selectBike(pic3)} alt={`${title}`} src={`../assets/productos/${pic3}`} />
            <img className="pic4 pic-detail" onClick={() => selectBike(pic4)} alt={`${title}`} src={`../assets/productos/${pic4}`} />

            <div className='title'>
                <h3>{title}</h3>
                <h4 className='price'>${price}</h4>
            </div>

            <div className="detail">
                <p className='detail__description'>{description}</p>
                <Autocomplete
                    disablePortal
                    className='autocomplete'
                    id="combo-box-demo"
                    options={sizes}
                    sx={{ width: 300 }}
                    value={size}
                    onChange={(e, newValue) => setSize(newValue)}
                    renderInput={(params) => <TextField {...params} label="Talles disponibles" />}
                />
            </div>
        </div>
        </>
    )
}

export default ItemDetail