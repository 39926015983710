import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { categories } from '../../utils/products';
import './NavigableMenu.css';


const NavigableMenu = ({classProp}) => {

    const [availableCategories, setCategories] = useState([])

    // Menu item de Material UI
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    /* "getCategories" obtiene de la db de firebase las categorías, con el map traigo el dato de todas 
    las categorias y con el reduce las guardo en valores únicos ya que si no se repiten por cada vez 
    que un producto tiene esa categoria*/

    const getCategories = async () => {

        const productCat = categories;
        setCategories(productCat)
    };

    useEffect(() => {

        getCategories()

    }, [])

    return (
        <nav className='nav__container'>
            <ul className={classProp}>
                <li>
                    <Link to={"/"}>
                        <Button
                            variant="text"
                            className='nav-btn'
                            sx={{ my: 2, color: { xs: '#1976D2', md: 'white'}}}
                        >
                            Home
                        </Button>
                    </Link>
                </li>
                <li>
                    <Button
                        className='nav-btn'
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        variant="text"
                        disableRipple
                        aria-expanded={open ? 'true' : undefined}
                        sx={{ my: 2, color: { xs: '#1976D2', md: 'white'}}}
                        onClick={handleClick}
                    >
                        Categorías
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}>
                        {availableCategories.map((cat, i) => {
                            return (
                                <Link to={`/category/${cat}`} key={i}>
                                    <MenuItem onClick={handleClose}>
                                        {cat}
                                    </MenuItem>
                                </Link>
                            )
                        })}
                    </Menu>
                </li>
            </ul>
        </nav>
    )
}

export default NavigableMenu;