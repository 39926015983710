import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './Item.css';

const Item = ({ item }) => {

    const { title, price, pic1, id } = item;

    return (
        <div className='item-list'>
            <img className='img-list' alt={`${title}`} src={`../assets/productos/${pic1}`}/>
            <h3>{title}</h3>
            <p>${price}</p>
            <Link to={`/item/${id}`}>
                <Button variant="contained">Ver detalles</Button>
            </Link>
        </div>
    )
}

export default Item