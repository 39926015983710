import Carrousel from "../../components/Carrousel/Carrousel"
import ItemListContainer from "../../components/ItemListContainer/ItemListContainer"

const Home = () => {

    return (
        <>
            <Carrousel/>
            <ItemListContainer greeting={'Productos Disponibles'}/>
        </>
    )
}

export default Home