import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { products } from '../../utils/products';
import ItemDetail from '../ItemDetail/ItemDetail';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import './ItemDetailContainer.css';

const ItemDetailContainer = () => {

    const [item, setItem] = useState({})
    const navigate = useNavigate()

    const { id } = useParams()

    const productFilter = async () => {
        const productList = products;
        let product = productList.filter(el => el.id == id)[0];
        
        if (product === undefined) {
            navigate('/*')
        } else {
            setItem(product)
        }
    }

    useEffect(() => {

        productFilter()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {Object.keys(item).length === 0 ? <SpinnerLoader /> : <ItemDetail prop={item} />}
        </>
    )
}

export default ItemDetailContainer